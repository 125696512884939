/* eslint-disable no-undef */
import Script from 'next/script';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

// Utils
import useUser from '@/utils/hooks/swr/useUser';

// Lib
import { LOCAL } from '@/lib/environments';

export const DataDogErrorMonitoring = () => {
    const { user } = useUser({ revalidateOnMount: false });
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_ENV === LOCAL) {
            return;
        }
        datadogRum.init({
            applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
            clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
            site: process.env.NEXT_PUBLIC_DATADOG_SITE,
            service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
            env: process.env.NEXT_PUBLIC_DATADOG_ENV,
            allowedTracingUrls: [process.env.NEXT_PUBLIC_BASE_API_URL],
            // Specify a version number to identify the deployed version of your application in Datadog
            version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true
        });
    }, []);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_ENV === LOCAL || !user) {
            return;
        }

        datadogRum.setUser({ id: user.id });
    }, [user]);

    if (process.env.NEXT_PUBLIC_ENV === LOCAL) return null;

    return <Script id="data-dog-error-monitoring"></Script>;
};
