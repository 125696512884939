import { useRouter } from 'next/router';

// Lib
import { GIVEAWAY_PICKER, GIVEAWAY_PICKER_RESULTS } from '@/lib/routes/publicRoutes';

// Scripts
import GoogleTagManager from '@/components/tools/GoogleTagManager';
import { DataDogErrorMonitoring } from '@/components/tools/DataDogErrorMonitoring';
import { DataDogLogCollection } from '@/components/tools/DataDogLogCollection';
import { AdobeExpress } from '@/components/tools/AdobeExpress';
import { MixPanelInitialize } from '@/components/tools/MixPanel';
import { IsUserLoggedIn } from '@/components/tools/IsUserLoggedIn';
import DeleteOldCookies from '@/components/tools/DeleteOldCookies';

const Tools = () => {
    const router = useRouter();

    if (router.pathname === GIVEAWAY_PICKER || router.pathname === GIVEAWAY_PICKER_RESULTS) {
        return (
            <>
                <DataDogErrorMonitoring />
                <DataDogLogCollection />
            </>
        );
    }

    return (
        <>
            <GoogleTagManager />
            <DataDogErrorMonitoring />
            <DataDogLogCollection />
            <AdobeExpress />
            <MixPanelInitialize />
            <IsUserLoggedIn />
            {/* This is a custom script to delete old cookies from user's browsers => remove this after a while : https://iconosquare.atlassian.net/browse/ICO-2059*/}
            <DeleteOldCookies />
        </>
    );
};

export default Tools;
