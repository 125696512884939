
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import useTranslation from 'next-translate/useTranslation';
import { DefaultSeo } from 'next-seo';
import SEO from '../../next-seo.config';
import '../styles/styles.css';
// Custom fonticon
import 'public/fonts/fonticon/style.css';
// Utils
import { GlobalInfoProvider } from '@/utils/hooks/context/useGlobalInfo';
import { useUtmDataCookie } from '@/utils/hooks/utils/useUtmDataCookie';
import { usePartnerStackCookie } from '@/hooks/utils/usePartnerStackCookie';
import { ProfilesStoreProvider } from '@/utils/zustand/providers/profiles-provider';
import { SignUpStoreProvider } from '@/utils/zustand/providers/sign-up-provider';
import { ToggleLayoutPlatformProvider } from '@/utils/zustand/providers/toggle-layout-platform-provider';
import { LoggedInStoreProvider } from '@/utils/zustand/providers/logged-in-provider';
// Types
import type { AppProps } from 'next/app';
// Scripts for tools
import Tools from '@/components/tools';
function App({ Component, pageProps }: AppProps) {
    const { t } = useTranslation('meta');
    useUtmDataCookie();
    usePartnerStackCookie();
    return (<>
            <DefaultSeo openGraph={{
            description: t('description')
        }} description={t('description')} {...SEO}/>
            <ToggleLayoutPlatformProvider>
                <LoggedInStoreProvider>
                    <ProfilesStoreProvider>
                        <SignUpStoreProvider>
                            <GlobalInfoProvider>
                                <Component {...pageProps}/>
                                <Tools />
                            </GlobalInfoProvider>
                        </SignUpStoreProvider>
                    </ProfilesStoreProvider>
                </LoggedInStoreProvider>
            </ToggleLayoutPlatformProvider>
        </>);
}
const __Next_Translate__Page__19498ec86c5__ = App;

    export default __appWithI18n(__Next_Translate__Page__19498ec86c5__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  